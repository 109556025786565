import {
  Box,
} from "../../../components/StyledComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ALYX_LOGO from "../../../assets/images/1017-ALYX-9SM-LOGO.png";
import ALAMO_LOGO from "../../../assets/images/Alamo Records Logo.png";
import ALYX_COVER from "../../../assets/images/ALYX ETHEL COVER FINAL.png";
import styled from "styled-components";

const Button = styled.button`
  border: 2px solid #000;
  font-family: AlyxFont;
`;

const Showcase = () => {
  return (
    <Box
      className="relative h-screen w-full overflow-hidden"
    >
      <div
        className="pt-6"
      >
        <img src={ALAMO_LOGO} className="w-52 m-auto pb-6" />
      </div>
      <div>
        <img src={ALYX_COVER} className="w-full md:w-1/5 px-5 md:px-0 m-auto" />
      </div>
      <div>
        <img src={ALYX_LOGO} className="md:w-1/3 m-auto px-2 md:px-0 pt-6 md:pt-5 pb-8 md:pb-6" />
      </div>
      <div className="text-center mb-6 w-72 md:w-48 m-auto">
        <a href="https://www.nts.live/shows/alamo-records" target="_blank">
          <Button
            className="text-black py-2 w-full m-auto uppercase font-alyxfont text-2xl md:text-xl"
          >
            nts radio
          </Button>
        </a>
      </div>
      <div className="text-center w-72 md:w-48 m-auto">
        <a href="https://1017alyx9sm.lnk.to/comingsoon" target="_blank">
          <Button
            className="text-black py-2 w-full m-auto uppercase font-alyxfont text-2xl md:text-xl"
            
          >
            coming soon
          </Button>
        </a>
      </div>
    </Box>
  );
};

export default Showcase;
